import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Figure from "../components/Figure"
import { H1, H2, H3, H4, P } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj15 from "../imgObjs/imgObj15"

import imgGM from "../img/0600/3x4/Geesche-Martin.jpg"
import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Schwangerschaft"
      keywords={[
        `Psychosoziale Arbeit`,
        `Frankfurt`,
        `Beratung`,
        `Geesche Martin`,
      ]}
    />
    <Figure caption="">
      <PictureSet imgObj={imgObj15} />
    </Figure>
    <Section>
      <Flex flexDirection={"row-reverse"}>
        <Box width={[1, 1, 1 / 2, 1 / 3]}>
          <P textAlign={"right"}>
            <em>
              „Was wäre das Leben, hätten wir nicht den Mut, etwas zu
              riskieren.“
            </em>
            <br />— Vincent van Gogh
          </P>
        </Box>
      </Flex>
      <H1>Schwangerschaft</H1>

      <H3>Meine Angebote in der Schwangerschaft</H3>
      <P>
        Für viele Frauen ist eine Schwangerschaft ein freudiges Ereignis. Oft
        geht sie aber auch mit Unsicherheiten, vielen Fragen und manchmal auch
        Ängsten vor der Geburt und der neuen Rolle als Mutter einher. Sie freuen
        sich UND haben gleichzeitig auch Zweifel.
      </P>
      <P>
        Einige Frauen haben körperliche Beschwerden unterschiedlichster Art. Sie
        reichen von Übelkeit und Erbrechen über Rückenschmerzen,
        Schambeinbeschwerden, bis zu Wassereinlagerungen, Schlafstörungen,
        Kopfschmerzen, Krampfadern, tauben Händen etc.
      </P>
      <P>
        In dieser sensiblen Phase möchte ich Sie begleiten und Ihnen mit{" "}
        <Link to={"/psychosoziale-beratung"}>Psychosozialer Beratung</Link>{" "}
        unterstützend zur Seite stehen.
      </P>
      <P>
        Zusätzlich steht mir die <Link to={"/akupunktur"}>Akupunktur</Link> als
        hilfreiche Methode zur Verfügung, besonders Ihre körperlichen
        Beschwerden sehr wirksam zu behandeln und zu lindern. Die Kosten der
        Unterstützung in der Schwangerschaft kann über die Krankenversicherung
        abgerechnet werden.
      </P>

      <H4>Individuelle Geburtsvorbereitung</H4>
      <P>
        Vielleicht finden Sie nicht die Zeit für einen Geburtsvorbereitungskurs
        in der Gruppe. Deshalb biete ich eine individuelle Geburtsvorbereitung
        von 2-3 Stunden an, in denen ich Ihnen einen Überblick über die Geburt
        und das Geburtsgeschehen vermittle und in denen viel Zeit für Ihre
        individuellen Fragen zur Verfügung steht.
      </P>
      <P>
        Die Kosten werden von den Krankenkassen übernommen und direkt mit den
        Kassen abgerechnet. Die Kosten Ihres Partners betragen 30 Euro.
      </P>

      <H4>Geburtsvorbereitende Akupunktur</H4>
      <P>
        Mit einer besonderen Akupunkturbehandlung kann der Gebärmutterhals auf
        die Geburt vorbereitet werden. Er soll dehnbarer werden, um sich bei
        guter Wehentätigkeit schneller zu öffnen. Dadurch kann die Geburt
        deutlich verkürzt und der Schmerz reduziert werden.
      </P>
      <P>
        Sie können mit der geburtsvorbereitenden Akupunktur vier bis fünf Wochen
        vor dem errechneten Geburtstermin beginnen und sollten einmal pro Woche
        einen Termin wahrnehmen.
      </P>
      <P>
        Die Kosten von 18 Euro pro Sitzung sind keine Leistung der
        Krankenkassen, werden aber von einigen Versicherungen übernommen. Bitte
        erfragen sie dies.
      </P>

      <H4>Moxabehandlung bei Steißlage</H4>
      <P>
        Bei dieser wirksamen Methode aus der chinesischen Medizin werden
        Akupunkturpunkte durch Wärme angeregt. Diese Behandlung unterstützt das
        Ungeborene dabei, sich aus der Steißlage in die Schädellage zu drehen.
        Mit der ersten Behandlung sollten Sie ab der 34. SSW beginnen.
      </P>
      <P>Die Kosten werden von den Krankenkassen übernommen</P>
    </Section>
  </Layout>
)

export default IndexPage
